import React, {useMemo} from 'react';
import { useGames } from '../context/GamesContext';
import GamesGrid from '../components/GamesGrid';
import {Alert, Space} from "antd";
import DiceLoader from "../components/DiceLoader";

const HomePage: React.FC<{ searchQuery: string }> = ({ searchQuery }) => {
    const { games, loading, error, refreshGames } = useGames();

    // Example usage: refresh games with specific parameters
    // const handleRefreshGames = () => {
    //     const params = {
    //         'sort[0]': 'game_name:asc',
    //         'filters[game_type][$eq]': 'Base Game',
    //     };
    //     refreshGames(params);
    // };

    const filteredGames = useMemo(() => {
        return games.filter(game =>
            game.attributes.game_name.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }, [games, searchQuery]);

    if (loading) return <div><DiceLoader message={"Loading games..."} /></div>;
    if (error) return <div>Error fetching games: {error.message}</div>;

    return (

        <Space direction="vertical" size="large" style={{ width: '100%' }}>
            <br/>
            <Alert
                message="!!! AUTOMA !!!"
                description="At the moment Automa (or one player setup), if it has any specific setup rules, is not supported for any game. Will be added in future."
                type="warning" showIcon
            />
            <GamesGrid games={filteredGames} />
        </Space>

    );
};

export default HomePage;
